@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.prose h5 {
  color: #111827;
  font-weight: 500;
  margin-top: 2em;
  margin-bottom: 0.5em;
  font-style: oblique;
  line-height: 1.5;
}

.prose h6 {
  color: #111827;
  font-weight: 500;
  text-decoration:underline;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.5;
}